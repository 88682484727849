import { useRef, useState, useEffect, Fragment } from 'react';
import { motion } from 'framer-motion';
import CanvasGraph from './CanvasGraph';
import handleStreamStarted from '../utils/handleStreamStarted';

const Listen = (props) => {

  const { windowWidth, windowHeight, audio, audioFirefox, isPlaying, setIsPlaying, streamSrc } = props;
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [volumeSliderWidth, setVolumeSliderWidth] = useState(0);

  const volumeSlider = useRef();
  const styles = useRef();

  const handleStreamStartedWithData = () => {
    handleStreamStarted(props);
  }

  useEffect(() => {
    if (volumeSlider.current && windowWidth && windowHeight && isPlaying === true) {
      setVolumeSliderWidth(volumeSlider.current.offsetWidth - 12);
    }
  }, [windowWidth, windowHeight, isPlaying]);

  return (
    <Fragment>
      {
        isPlaying === true &&
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`listen__outer ${windowWidth <= windowHeight ? `col-xxs-12` : `col-xxs-6 offset-xxs-6 offset-sm-0`} col-sm-5 col-smmd-4 col-md-3`}
        >
          <div className={`listen__wrapper${isPlaying === true ? ' playing' : ''}`}>
            <style ref={styles}>{`
              input[type=range].volume::-webkit-slider-runnable-track {
                width: 100%;
                height: 1px;
                cursor: pointer;
                box-shadow: none;
                background-color: transparent;
                ${muted === false ?
                `background-image: linear-gradient(90deg, black 0, black calc(${volume * volumeSliderWidth}px), transparent calc(${volume * volumeSliderWidth}px), transparent calc(${volume * volumeSliderWidth + 11}px), black calc(${volume * volumeSliderWidth + 12}px));`
                :
                `background-image: linear-gradient(90deg, transparent 0, transparent 11px, black 12px);`
              }
                border-radius: 0;
                border: none;
              }

              input[type=range].volume::-moz-range-track {
                width: 100%;
                height: 1px;
                cursor: pointer;
                box-shadow: none;
                background-color: transparent;
                ${muted === false ?
                `background-image: linear-gradient(90deg, black 0, black calc(${volume * volumeSliderWidth}px), transparent calc(${volume * volumeSliderWidth}px), transparent calc(${volume * volumeSliderWidth + 11}px), black calc(${volume * volumeSliderWidth + 12}px));`
                :
                `background-image: linear-gradient(90deg, transparent 0, transparent 11px, black 12px);`
              }
                border-radius: 0;
                border: none;
              }
            `}</style>
            <button
              className={`listen__button${isPlaying === true ? ' active' : ''}`}
              onClick={handleStreamStartedWithData}
            >
              {
                isPlaying === false ?
                  <svg
                    className="listen__button__icon listen__button__icon--play"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40.12"
                    height="30"
                    viewBox="3 0 40.12 30"
                  >
                    <g id="Polygon_1" data-name="Polygon 1" transform="translate(26.889 38.441) rotate(-150)">
                      <path d="M13.8,2.94a2,2,0,0,1,3.45,0L29.282,23.45a2,2,0,0,1-1.725,3.012H3.492A2,2,0,0,1,1.767,23.45Z" stroke="none" />
                      <path d="M 15.5244836807251 2.952449798583984 C 15.3522834777832 2.952449798583984 14.92352294921875 3.000579833984375 14.66195297241211 3.446439743041992 L 2.629583358764648 23.95616912841797 C 2.364843368530273 24.40744018554688 2.537723541259766 24.80792999267578 2.624343872070312 24.95916938781738 C 2.710964202880859 25.11042022705078 2.968914031982422 25.46218872070312 3.492103576660156 25.46218872070312 L 27.55686378479004 25.46218872070312 C 28.08005332946777 25.46218872070312 28.33800315856934 25.11042022705078 28.42462348937988 24.95916938781738 C 28.51124382019043 24.80792999267578 28.68412399291992 24.40744018554688 28.41939353942871 23.95616912841797 L 16.38701248168945 3.446449279785156 C 16.12543487548828 3.000589370727539 15.69668388366699 2.952449798583984 15.5244836807251 2.952449798583984 M 15.5244836807251 1.952455520629883 C 16.19380569458008 1.952455520629883 16.86312866210938 2.281780242919922 17.24953460693359 2.9404296875 L 29.28191375732422 23.45015907287598 C 30.0641040802002 24.78343963623047 29.10265350341797 26.46219062805176 27.55686378479004 26.46219062805176 L 3.492103576660156 26.46219062805176 C 1.946313858032227 26.46219062805176 0.98486328125 24.78343963623047 1.767053604125977 23.45015907287598 L 13.7994327545166 2.9404296875 C 14.18583869934082 2.281780242919922 14.85516166687012 1.952455520629883 15.5244836807251 1.952455520629883 Z" stroke="none" fill="#000" />
                    </g>
                  </svg>
                  :
                  <svg
                    className="listen__button__icon listen__button__icon--pause"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="25"
                    viewBox="0 0 22 25"
                  >
                    <g id="Group_6" data-name="Group 6" transform="translate(-1463 -78)">
                      <g id="Rectangle_11" data-name="Rectangle 11" transform="translate(1463 78)" stroke="#000" strokeWidth="1">
                        <rect width="8" height="25" rx="2" stroke="none" />
                        <rect x="0.5" y="0.5" width="7" height="24" rx="1.5" />
                      </g>
                      <g id="Rectangle_12" data-name="Rectangle 12" transform="translate(1477 78)" stroke="#000" strokeWidth="1">
                        <rect width="8" height="25" rx="2" stroke="none" />
                        <rect x="0.5" y="0.5" width="7" height="24" rx="1.5" />
                      </g>
                    </g>
                  </svg>
              }
            </button>
            <div className="listen__volume__wrapper">
              <button
                className={`listen__volume__button button--plain${muted === true ? ' muted' : ''}`}
                onClick={() => {
                  setMuted(!muted);
                }}
              >
                <svg className="listen__volume__icon" xmlns="http://www.w3.org/2000/svg" width="18.805" height="27.784" viewBox="0 0 18.805 27.784">
                  <g transform="translate(0 -6.747)" fill="none">
                    <path d="M16.618,34.26l-6.279-4.975H2a2,2,0,0,1-2-2v-13a2,2,0,0,1,2-2H9.742l6.765-5.274c.967-.754,2.17.2,2.177,1.724L18.8,32.514c.006,1.173-.695,2.017-1.46,2.017A1.162,1.162,0,0,1,16.618,34.26Z" stroke="none" />
                    <path d="M 17.34477233886719 33.53094482421875 C 17.36576843261719 33.53094100952148 17.42571449279785 33.51058578491211 17.50233459472656 33.43355560302734 C 17.69360542297363 33.24127578735352 17.80660629272461 32.89959335327148 17.80460548400879 32.51937484741211 L 17.68400573730469 8.740124702453613 C 17.6807861328125 8.056705474853516 17.33056640625 7.747195243835449 17.22467613220215 7.747195243835449 C 17.20745468139648 7.747195243835449 17.17135620117188 7.760984897613525 17.12173461914062 7.799664974212646 L 10.08548545837402 13.28500556945801 L 1.999805450439453 13.28500556945801 C 1.448505520820618 13.28500556945801 0.9999954700469971 13.73391532897949 0.9999954700469971 14.28570556640625 L 0.9999954700469971 27.28530502319336 C 0.9999954700469971 27.83659553527832 1.448505520820618 28.28510475158691 1.999805450439453 28.28510475158691 L 10.68717575073242 28.28510475158691 L 17.23910522460938 33.4761848449707 C 17.2901725769043 33.5166015625 17.32793998718262 33.53094482421875 17.34477233886719 33.53094482421875 M 17.34477615356445 34.53094482421875 C 17.10226631164551 34.53094482421875 16.85334587097168 34.44618606567383 16.61849594116211 34.26030349731445 L 10.33903503417969 29.28510475158691 L 1.999805450439453 29.28510475158691 C 0.8955054879188538 29.28510475158691 -4.519348294707015e-06 28.38960456848145 -4.519348294707015e-06 27.28530502319336 L -4.519348294707015e-06 14.28570556640625 C -4.519348294707015e-06 13.18050479888916 0.8955054879188538 12.28500556945801 1.999805450439453 12.28500556945801 L 9.741745948791504 12.28500556945801 L 16.50690460205078 7.01100492477417 C 17.47439956665039 6.256803512573242 18.67679405212402 7.209005832672119 18.68399620056152 8.735404968261719 L 18.80459594726562 32.51430511474609 C 18.81075477600098 33.68761444091797 18.10969543457031 34.53093338012695 17.34477615356445 34.53094482421875 Z" stroke="none" fill="#000" />
                  </g>
                </svg>
              </button>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={muted === true ? 0 : volume}
                ref={volumeSlider}
                onChange={(e) => {
                  setVolume(e.target.value);
                  if (e.target.value === 0) {
                    setMuted(true);
                  } else {
                    setMuted(false);
                  }
                  if (audio.current) {
                    audio.current.volume = e.target.value;
                  }
                }}
                className="volume"
              />
            </div>
          </div>
        </motion.div>
      }
      {
        isPlaying === true &&
        <CanvasGraph key="graph" {...props} />
      }
      {
        isPlaying === false &&
        <motion.div
          key="start"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`start__outer ${windowWidth <= windowHeight ? `col-xxs-12` : `col-xxs-6 offset-xxs-6 offset-sm-0`} col-sm-5 col-smmd-4 col-md-3`}
        >
          <div
            className={'start__wrapper'}
            style={{
              // height: windowWidth > 767 ? windowHeight - 100 + 'px' : null
            }}
          >
            <button
              className="button start__button"
              onClick={handleStreamStartedWithData}
            >Start listening</button>
          </div>
        </motion.div>
      }
      <audio
        ref={audio}
        crossOrigin="Anonymous"
        src={streamSrc}
        preload='none'
        volume={volume}
        muted={muted}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
      {
        navigator.userAgent.indexOf('Firefox') > -1 &&
        <audio
          ref={audioFirefox}
          crossOrigin="Anonymous"
          src={streamSrc}
          preload='none'
          volume={volume}
          muted={muted}
        />

      }
    </Fragment>
  )
}

export default Listen;