import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import InfoMain from '../components/InfoMain';

const Info = (props) => {

  const { configData } = props;
  const { info } = configData;

  if (info) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`content container-fluid`}
      >
        <div className="row row--main no-margins">
          <div className="col-xxs-12 col-sm-7 col-smmd-8 col-md-9 column--info">
            {
              info &&
              <InfoMain {...props} />
            }
            <div className="info__separator" />
            <div className="row info__section">
              <div className="col-xxs-12 col-md-4">
                <h2 className="font--sabon--italic info__section__heading">News & Public Events</h2>
              </div>
              <div className="col-xxs-12 col-md-8">
                <ul className="info__section__list">
                  {
                    info.newsAndInterventions &&
                    info.newsAndInterventions[0] &&
                    info.newsAndInterventions.map(
                      (item, i) => (
                        <li className="info__section__list__item" key={i}>
                          <h3>{item.date}</h3>
                          <div>
                            {item.content && parse(item.content)}
                          </div>
                        </li>
                      )
                    )
                  }
                </ul>
              </div>
            </div>
            <div className="row info__section">
              <div className="col-xxs-12 col-md-4">
                <h2 className="font--sabon--italic info__section__heading">Credits</h2>
              </div>
              <div className="col-xxs-12 col-md-8">
                {
                  info.credits &&
                  info.credits[0] &&
                  info.credits.map(
                    (item, i) => (
                      <div key={i}>
                        {item && parse(item)}
                      </div>
                    )
                  )
                }
              </div>
            </div>

            <div className="row info__section">
              <div className="col-xxs-12 col-md-4">
                <h2 className="font--sabon--italic info__section__heading">Special Thanks:</h2>
              </div>
              <div className="col-xxs-12 col-md-8">
                {
                  info.specialThanks &&
                  info.specialThanks[0] &&
                  info.specialThanks.map(
                    (item, i) => (
                      <div key={i}>
                        {item && parse(item)}
                      </div>
                    )
                  )
                }
              </div>
            </div>

            <div className="row">
              <div className="col-xxs-12">
                {parse(info.commission)}
              </div>
            </div>
          </div>
        </div>
      </motion.main>
    );
  } else {
    return null;
  }
}

export default Info;
