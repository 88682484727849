import { useEffect } from 'react';

const CanvasGraph = (props) => {

  const { canvas, windowHeight, windowWidth, noCaptureStream } = props;

  useEffect(() => {
    if (windowHeight && windowWidth && canvas.current) {
      canvas.current.width = canvas.current.parentNode.offsetWidth;
      canvas.current.height = canvas.current.parentNode.offsetHeight;
    }
  }, [windowHeight, windowWidth, canvas]);

  if (noCaptureStream !== true) {
    return (
      <div className={`visualiser-test__wrapper ${windowWidth <= windowHeight ? `col-xxs-12` : `col-xxs-6 offset-xxs-6 offset-sm-0`} col-sm-5 col-smmd-4 col-md-3`}>
        <canvas className="visualiser-test" ref={canvas} width={60} height={40} />
      </div>
    );
  } else {
    return null;
  }
}

export default CanvasGraph;