import { useRef, useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const Chatbot = (props) => {

  const { windowWidth, windowHeight, messageHistory, setMessageHistory, websocket, transcriptIsActive, setTranscriptIsActive, chatIsMinimised, setChatIsMinimised } = props;
  const [messageText, setMessageText] = useState('');

  const list = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    websocket.send(JSON.stringify({
      msg: "chat",
      text: messageText
    }));
    setMessageHistory(
      [
        ...messageHistory,
        {
          type: 'sent',
          text: messageText
        }
      ]
    );
    setMessageText('');
  }

  useEffect(() => {
    if (messageHistory && list.current) {
      list.current.scrollTo({
        left: 0,
        top: list.current.scrollHeight,
        behavior: 'smooth'
      })
    }
  }, [messageHistory, list]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`chatbot__wrapper${transcriptIsActive === true ? ' inactive' : ' active'}${chatIsMinimised === true ? ' minimised' : ''}`}
    >
      <div className="chatbot">
        {
          (chatIsMinimised === false || (windowWidth < 768 && windowHeight >= windowWidth)) &&
          <motion.ul
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
              className="messages__list"
              ref={list}
          >
            {
              messageHistory &&
              messageHistory[0] &&
              messageHistory.map(
                (message, i) => (
                  message.text && message.text !== '' &&
                  <li
                    key={i}
                    className={`messages__list__item messages__list__item--${message.type}`}
                  >
                    <p className={`messages__list__item__text messages__list__item__text--${message.type}`}>{message.text}</p>
                  </li>
                )
              )
            }
          </motion.ul>
        }
        <AnimatePresence>
          {
            (windowWidth >= 768 || transcriptIsActive === false || windowWidth > windowHeight) &&
            chatIsMinimised === false &&
            <motion.form
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onSubmit={handleSubmit}
              className="chatbot__inputs"
            >
              <textarea
                className="chatbot__textarea"
                  placeholder="Type here..."
                  onChange={(e) => setMessageText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleSubmit(e);
                    }
                  }}
                  value={messageText}
                />
              <input className="chatbot__submit button" type="submit" value="send" />
            </motion.form>
          }
        </AnimatePresence>
          <div className="chatbot__initialiser">
            {
              chatIsMinimised === true || (windowWidth < 768 && windowWidth <= windowHeight && transcriptIsActive === true) ?
              <button
                className="button chatbot__initialiser__button"
                onClick={() => {
                  websocket.send(JSON.stringify({
                    msg: "chat_begin",
                    text: "chat_begin"
                  }));
                  setChatIsMinimised(false);
                  setTranscriptIsActive(false);
                }}
              >
                I’d like to chat
              </button>
              :
               <button
                className="button chatbot__initialiser__button"
                onClick={() => {
                  console.log(chatIsMinimised)
                  setTranscriptIsActive(true);
                  setChatIsMinimised(true);
                }}
              >
                I’m only here to listen
              </button>
            }
          </div>
      </div>
    </motion.div>
  )
}

export default Chatbot;
