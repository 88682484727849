import { useState } from 'react';

const ErrorMessage = () => {

  const [isActive, setActive] = useState(false); // set this to true to display the maintenance message

  if (isActive === true) {
    return (
      <div
        className="error-message"
        style={{
          maxWidth: 'calc(100% - 40px)',
          maxHeight: 'calc(100% - 40px)',
          position: 'fixed',
          border: '1px solid black',
          background: 'white',
          zIndex: 999,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <div
          className="error-message__inner"
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            padding: '20px'
          }}
        >
          <button
            className="error__close"
            onClick={() => setActive(false)}
            aria-label="Close message"
          >
            <div className="error__close__inner" />
          </button>
          <div className="error__content">
            <p>The audio stream is currently unavailable because of scheduled server maintenance. Please come back later!</p>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default ErrorMessage;
