import { useState, useEffect } from 'react';
import parse from 'html-react-parser';

const InfoMain = (props) => {

  const { configData, isIntro } = props;
  const { info, introText } = configData;
  const [timeElapsedSinceStart, setTimeElapsedSinceStart] = useState('');

  useEffect(() => {
    let interval;
    const startDateTime = new Date();
    startDateTime.setDate(22);
    startDateTime.setMonth(0);
    startDateTime.setFullYear(2022);
    startDateTime.setHours(0);
    startDateTime.setMinutes(0);
    startDateTime.setSeconds(0);
    startDateTime.setMilliseconds(0);

    const startTimestamp = startDateTime.getTime();

    const updateTimeElapsedSinceStart = () => {
      const currentDateTimestamp = new Date().getTime();
      let timeElapsed = currentDateTimestamp - startTimestamp;

        let seconds = Math.floor((timeElapsed / 1000) % 60),
          minutes = Math.floor((timeElapsed / (1000 * 60)) % 60),
          hours = Math.floor((timeElapsed / (1000 * 60 * 60)) % 24),
          days = Math.floor((timeElapsed / (1000 * 60 * 60 * 24)));

        seconds = seconds.toString().padStart(2, '0');
        minutes = minutes.toString().padStart(2, '0');
        hours = hours.toString().padStart(2, '0');

        setTimeElapsedSinceStart(`${ days } days ${ hours }h ${minutes}m ${seconds}s`);

    }

    interval = setInterval(updateTimeElapsedSinceStart, 1000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <div>
      {isIntro === true && introText ? parse(introText) : info?.main ? parse(info.main) : ''}
      <p>The <i>BroadCaster</i> began speaking on January 28, 2022 and has been speaking for <b><i>{timeElapsedSinceStart}</i></b></p>
    </div>
  )
}

export default InfoMain;
