import { motion } from 'framer-motion';
import Transcript from '../components/Transcript';
import Chatbot from '../components/Chatbot';

const Main = (props) => {

  const { windowWidth, windowHeight, isPlaying } = props;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`content container-fluid`}
    >
        <div className="row row--main no-margins">
          {
            (windowWidth >= 768 || windowWidth > windowHeight) &&
            <div className="column--main column--main--transcript col-xxs-6 col-md-12">
              <Transcript {...props} />
            </div>
          }
          <div className={`${windowWidth > windowHeight ? 'col-xxs-6 col-xs-6' : 'col-xxs-12'} col-sm-5 col-smmd-4 col-md-3 column--main column--controls`}>
            {
              windowWidth < 768 && windowWidth <= windowHeight &&
              <Transcript {...props} />
          }
          {
            isPlaying === true &&
            <Chatbot {...props} />
          }
          </div>
        </div>
    </motion.main>
  )
}

export default Main;