import { Link } from 'react-router-dom';

const Header = (props) => {

  return (
    <header className="header col-xxs-12 col-sm-5 col-smmd-4 col-md-3">
      <div className="header__inner">
        <Link
          className={`button--small listen__link${props.location.pathname === "/" ? ` active` : ``}`}
          to="/"
        >
          home
        </Link>
        <Link
          className={`button--small listen__link${props.location.pathname === "/info" ? ` active` : ``}`}
          to="/info"
        >
          info
        </Link>
        <Link
          className={`button--small listen__link${props.location.pathname === "/reference" ? ` active` : ``}`}
          to="/reference"
        >
          reference
        </Link>
      </div>
    </header>
  )
}

export default Header;