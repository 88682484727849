const vertexShader = `
    uniform mat4 modelViewProjectMatrixInverse;
    varying vec3 rayDir;

    void main() {
        gl_Position = vec4(position,1);
        vec4 WPpos = modelViewProjectMatrixInverse * gl_Position;
        WPpos /= WPpos.w;
        rayDir = WPpos.xyz - cameraPosition;
    }
`;

export default vertexShader;