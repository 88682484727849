import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';

const ReferenceItem = (props) => {

  const { item, location } = props;
  const { id, short_text, long_text, url } = item;
  const [isHighlighted, setIsHighlighted] = useState(location.search === `?id=${id}`);

  useEffect(() => {
    if (location.search === `?id=${id}`) {
      setIsHighlighted(true);
    } else {
      setIsHighlighted(false);
    }
  }, [location.search, id, location]);

  return (
    <motion.li
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`reference__item${isHighlighted === true ? ' highlighted' : ''}`}
      id={`referenceItem${id}`}
    >
      <div className="reference__item__header__wrapper">
        <h2 className="reference__item__short-text">{short_text}</h2>
        {
          url && url !== '' &&
          <div className="reference__item__link__wrapper">
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="reference__item__link button"
              title="link"
              >
                <div className="reference__item__link__inner"></div>
            </a>
          </div>
        }
      </div>
      {
        long_text && long_text !== '' &&
        <div className="reference__item__long-text">{parse(long_text)}</div>
      }
    </motion.li>
  )
}

const Reference = (props) => {

  const { references } = props;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`content container-fluid`}
    >
      <div className="row row--main no-margins">
        <div className="col-xxs-12 col-sm-7 col-smmd-8 col-md-9 column--reference">
          <ul className="reference__list">
            {
              references &&
              references[0] &&
              references.map(
                (item, i) => (
                  <ReferenceItem
                    {...props}
                    key={i}
                    item={item}
                  />
                )
              )
            }
          </ul>
        </div>
      </div>
    </motion.main>
  )
}

export default Reference;