const handleStreamStarted = (props) => {

  const { audio, isPlaying, audioFirefox, faviconCanvas, audioCtx, canvas, dataArray, setBufferLength, analyser, raf, configData, setNoCaptureStream } = props;
  const { streamSrc } = configData;

  if (audio.current) {
    if (isPlaying !== true) {
      if (audioFirefox.current) {
        audioFirefox.current.setAttribute('src', '');
        audioFirefox.current.setAttribute('src', streamSrc);
        audioFirefox.current.load();
        audioFirefox.current.play();
      }
      audio.current.setAttribute('src', '');
      audio.current.setAttribute('src', streamSrc);
      audio.current.load();
      audio.current.play()
      .then(() => {
        if (!faviconCanvas.current) {
          faviconCanvas.current = document.createElement('canvas');
          faviconCanvas.current.width = 32;
          faviconCanvas.current.height = 32;
        }

        if (typeof audio.current !== 'undefined' && typeof canvas.current !== 'undefined') {
          if (typeof window.webkitAudioContext !== 'undefined') {
            audioCtx.current = new window.webkitAudioContext();
          } else {
            audioCtx.current = new window.AudioContext();
          }
          analyser.current = audioCtx.current.createAnalyser();
          let stream;

          if (navigator.userAgent.indexOf('Firefox') > -1) {
            stream = audio.current.mozCaptureStream();
          } else {
            if (typeof audio.current.captureStream === 'undefined') {
              setNoCaptureStream(true);
            };
            stream = audio.current.captureStream();
          }

          var source = audioCtx.current.createMediaStreamSource(stream);
          source.connect(analyser.current);
          analyser.current.fftSize = 2048;
          const newBufferLength = analyser.current.frequencyBinCount;

          setBufferLength(newBufferLength);
          dataArray.current = new Uint8Array(newBufferLength);
          analyser.current.getByteTimeDomainData(dataArray.current);

          const canvasCtx = canvas.current.getContext('2d');
          canvasCtx.clearRect(0, 0, canvas.current.width, canvas.current.height);

          const draw = () => {
            raf.current = requestAnimationFrame(draw);
            if (dataArray.current && canvas.current) {
              analyser.current.getByteTimeDomainData(dataArray.current);

              canvasCtx.clearRect(0, 0, canvas.current.width, canvas.current.height);
              canvasCtx.lineWidth = 2;
              canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
              canvasCtx.beginPath();
              const sliceWidth = canvas.current.width * 1.0 / newBufferLength;

              let x = 0;

              for (let i = 0; i < newBufferLength; i++) {

                const v = dataArray.current[i] / 128.0;
                const y = v * canvas.current.height / 2;

                if (i === 0) {
                  canvasCtx.moveTo(x, y);
                } else {
                  canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
              }

              canvasCtx.lineTo(canvas.current.width, canvas.current.height / 2);
              canvasCtx.stroke();

              const favicon = document.querySelector('#favicon');
              if (favicon && faviconCanvas.current) {
                const faviconCanvasCtx = faviconCanvas.current.getContext('2d');
                faviconCanvasCtx.clearRect(0, 0, 32, 32);
                faviconCanvasCtx.drawImage(canvas.current, 0, -16, 32, 64);
                const dataUrl = faviconCanvas.current.toDataURL();
                favicon.href = dataUrl;
              }
            }
          }

          draw();

        }
      })
      .catch(error => {
      });
    } else {
      cancelAnimationFrame(raf.current);
      audio.current.pause();

      if (audioCtx.current) {
        if (audioCtx.current.state !== 'closed') {
          audioCtx.current.close();
        }
      }

      if (audioFirefox.current) {
        audioFirefox.current.pause();
      }
    }
  }
}

export default handleStreamStarted;