import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import InfoMain from './InfoMain';

const TranscriptParagraph = (props) => {

  const { item, typingParagraphIndex, setTypingParagraphIndex, index, wrapper, references, transcriptHistory } = props;
  const { text, annotations, ref_ids } = item;
  const [typedText, setTypedText] = useState('');
  const currentText = useRef('');
  const paragraph = useRef();
  const [itemReferences, setItemReferences] = useState([]);


  useEffect(() => {
    const itemReferencesArray = [];
    if (ref_ids) {
      if (ref_ids[0]) {
        for (let id of ref_ids) {
          for (let reference of references) {
            if (reference.id === id) {
              itemReferencesArray.push(reference);
            }
          }
        }
      }
    }
    setItemReferences(itemReferencesArray);
  }, [ref_ids, references]);

  useEffect(() => {
    let interval;

    const type = () => {
      if (currentText.current.length + 1 <= text.length) {
        currentText.current = text.substring(0, currentText.current.length + 1);
        setTypedText(currentText.current);
      } else {
        if (index < transcriptHistory.length - 1) {
          clearInterval(interval);
          setTypingParagraphIndex(index + 1);
        }
      }
    }

    if (typingParagraphIndex === index) {
      interval = setInterval(type, 30);
    } else if (typingParagraphIndex > index) {
      setTypedText(text);
    }

    return () => {
      clearInterval(interval);
    };
  }, [text, typingParagraphIndex, setTypingParagraphIndex, index, item, transcriptHistory]);

  useEffect(() => {
    if (typedText && wrapper.current) {
      wrapper.current.scrollTo({
        left: 0,
        top: wrapper.current.scrollHeight,
        behavior: 'smooth'
      })
    }
  }, [typedText, wrapper]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      ref={paragraph}
      className={`transcript__paragraph__wrapper${annotations && annotations[0] ? ' annotated' : ''}`}
    >
      <p className={`transcript__paragraph transcript__paragraph--${item.what}`}>{typedText}
        {/* HACK ... JON TODO: Figure out how to make a switch to turn off reference links! Unfortunately checking for item.show_refs prints a 0 when typing
        {
          typedText.length === text.length && 
          itemReferences &&
          itemReferences[0] &&
          [...new Set(itemReferences)].map(
            (item, i) => (
              <motion.span
                key={i}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Link
                  className="transcript__paragraph__reference-link"
                  to={`/reference/?id=${item.id}`}
                >
                  {' '}[{item.short_text}]
                </Link>
              </motion.span>
            )
          )
        } */}
      </p>
    </motion.div>
  )
}

const Transcript = (props) => {

  const { windowWidth, windowHeight, transcriptHistory, transcriptIsActive, setTranscriptIsActive, transcriptTitle, typingParagraphIndex, setTypingParagraphIndex, isPlaying } = props;
  const [transcriptTitleText, setTranscriptTitleText] = useState('');

  const wrapper = useRef();
  const questionWrapper = useRef();

  useEffect(() => {
    let interval;
    let currentText = '';
    let count = 0;

    const type = () => {
      currentText = transcriptTitle.substring(0, count);
      setTranscriptTitleText(currentText);
      if (currentText.length < transcriptTitle.length) {
        count++;
      } else {
        clearInterval(interval);
      }
    }

    if (transcriptTitle !== currentText) {
      interval = setInterval(type, 60);
    }
    return () => {
      clearInterval(interval);
    };
  }, [transcriptTitle]);

  useEffect(() => {
    if (questionWrapper.current && wrapper.current && transcriptTitleText && isPlaying) {
      const questionTop = windowWidth < 768 ? 12 : 0;
      const questionHeight = questionWrapper.current.offsetHeight + questionTop + 12;
      wrapper.current.style.paddingTop = windowWidth < 768 && transcriptIsActive === false ? '12px' : questionHeight + 'px';
      const mask = windowWidth < 768 && transcriptIsActive === false && windowWidth <= windowHeight ? 'none' : `linear-gradient(transparent 0, transparent ${questionHeight}px, white ${questionHeight + 20}px, white 100%)`;

      wrapper.current.setAttribute('style', wrapper.current.getAttribute('style') + `mask: ${mask}; -webkit-mask-image: ${mask}; -moz-mask-image: ${mask};`);
    }
  }, [windowWidth, windowHeight, transcriptIsActive, transcriptTitleText, isPlaying]);

  return (
    <div
      className={`transcript__wrapper${transcriptIsActive === true ? ' active' : ' inactive'}`}
      onClick={() => setTranscriptIsActive(true)}
      onFocus={() => setTranscriptIsActive(true)}
    >
      <div className="transcript__row row">
        <div
          className="col-xxs-12 col-md-8 transcript__col--question"
          ref={questionWrapper}
        >
          <AnimatePresence>
            {
              (windowWidth >= 768 || windowWidth > windowHeight || transcriptIsActive === true) && isPlaying === true &&
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="transcript__intro"
              >
                {transcriptTitleText}
              </motion.p>
            }
          </AnimatePresence>
        </div>
        <div className="col-xxs-12 col-md-4" />
        <AnimatePresence>
          {
            isPlaying === false &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={`col-xxs-12 col-sm-10 col-md-6 transcript__col--info${(windowWidth >= 768 || windowWidth > windowHeight || transcriptIsActive === true) && isPlaying === false ? ' transcript__col--intro' : ''}`}
            >
              {
                (windowWidth >= 768 || windowWidth > windowHeight || transcriptIsActive === true) && isPlaying === false &&
                <div className="transcript__info-section" key="info">
                  <InfoMain {...props} isIntro={true} />
                </div>
              }
            </motion.div>
          }
        </AnimatePresence>
        <AnimatePresence>
          {
            isPlaying === true &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="col-xxs-12 col-md-6 transcript__col--response"
              ref={wrapper}
            >
              <AnimatePresence>
                {
                  transcriptHistory &&
                  transcriptHistory[0] &&
                  transcriptHistory.map(
                    (item, i) => (
                      (windowWidth >= 768 || transcriptIsActive === true || typingParagraphIndex === i || i === transcriptHistory.length - 1 || windowWidth > windowHeight) &&
                      (transcriptHistory.length - i < 48) &&
                      <TranscriptParagraph
                        key={i}
                        item={item}
                        index={i}
                        typingParagraphIndex={typingParagraphIndex}
                        setTypingParagraphIndex={setTypingParagraphIndex}
                        wrapper={wrapper}
                        {...props}
                      />
                    )
                  )
                }
              </AnimatePresence>
            </motion.div>
          }
        </AnimatePresence>
      </div>
    </div>
  )
}

export default Transcript;