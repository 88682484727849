import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import ThreeCanvas from './ThreeCanvas';
import Main from '../views/Main';
import Info from '../views/Info';
import Reference from '../views/Reference';
import Listen from './Listen';
import ReceiveDataFromWebsocket from '../utils/ReceiveDataFromWebsocket';
import Header from './Header';
import ErrorMessage from './ErrorMessage';

const Content = (props) => {

  const { location, windowWidth, windowHeight, handleMouseMoveThrottled, configData, isPlaying, noCaptureStream } = props;

  return (
    <div
      className={`wrapper${noCaptureStream === true ? ' no-capture-stream' : ''}${isPlaying === false ? ' not-playing' : ''}`}
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
      onMouseMove={handleMouseMoveThrottled}
    >
      <ReceiveDataFromWebsocket {...props} />
      <AnimatePresence>
        {
          isPlaying === true &&
          <ThreeCanvas {...props} />
        }
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/info"
            element={
              <Info {...props} />
            }
          />
          <Route
            path="/reference"
            element={
              <Reference {...props} />
            }
          />
          <Route
            path="*"
            element={
              <Main {...props} />
            }
          />
        </Routes>
      </AnimatePresence>
      <Header {...props} />
      {
        configData &&
        <Listen {...props} streamSrc={configData?.streamSrc ? configData.streamSrc : null} />
      }
      <ErrorMessage />
    </div>
  )
}

export default Content;