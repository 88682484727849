import { useRef, useEffect, useMemo, useState } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import Camera from './Camera';
import lavaFragmentShader from './shaders/MandelbulbFragmentShader';
import lavaVertexShader from './shaders/MandelbulbVertexShader';

const lerp = (start, end, amount) => {
  return (1-amount)*start+amount*end
}

const lerpArray = (startArray, endArray, amount) => {
  const newArray = [];
  for (let i = 0; i < startArray.length; i++) {
    newArray.push((1-amount)*startArray[i]+amount*endArray[i])
  }
  return newArray;
}

const lerpAmount = 0.02;

const Mandelbulb = (props) => {

  const { simData, windowWidth, windowHeight } = props;
  const material = useRef();

  const targetSimData = useRef({});
  const currentSimData = useRef({
    power: 3.6,
    phase: [0.5, 0.5],
    diffuseColor: [0.2, 0.85, 0.99, 1.0],
    lightColor: [0.4, 0.09, 0.99, 0.0],

    position: 0.00,
    scale: 18,
  });

  const uniforms = useMemo(() => {
    return {
      "time": { value: 0.0 },
      "width": { value: 600.0 },
      "height": { value: 600.0 },
      "pixelSize": { value: 1.0 },
      "power": { value: 3.6 },
      "phase": { value: new THREE.Vector2(0.5, 0.5) },
      "bounding": { value: 3.5 },
      "max_iterations": { value: 3 },
      "bailout": { value: 12.0 },
      "diffuseColor": { value: new THREE.Vector4(0.2, 0.85, 0.99, 1.0) },
      "light": { value: new THREE.Vector3(1.0, 42.0, 38.0) },
      "backgroundColor": { value: new THREE.Vector4(1.0, 1.0, 0.4, 0.0) },
      "ambientColor": { value: new THREE.Vector4(0.99, 0.86, 0.6, 0.60) },
      "lightColor": { value: new THREE.Vector4(0.4, 0.09, 0.99, 0.0) },
      "eye": { value: new THREE.Vector3(0, 20, 0) },
      "specularity": { value: 0.29 },
      "rimLight": { value: 0.975 },
      "colorSpread": { value: 12.2 },
      "specularExponent": { value: 0.02 },
      "STEP_LIMIT": { value: 60 },
      "epsilonScale": { value: 0.9 },
      "modelViewProjectMatrixInverse": {
        value: [
          0.8572106957435608,
          0.1,
          0.0,
          0.0,
          0.0,
          0.4142135679721832,
          0.0,
          0.0,
          0.0,
          0.0,
          -9.0999998092651367,
          -4.999999046325684,
          0.0,
          0.0,
          60.000000953674316,
          6.0
        ]
      }
    }
  }, []);

  const mesh = useRef();

  const [isInitialized, setIsInitialized] = useState(false);
  const [bulbFloat, setBulbFloat] = useState(true);
  const [animating, setAnimating] = useState(true);
  const [bulbPos, setPos] = useState([0.0, 0.0]);
  const [bulbScale, setScale] = useState(40);

  useEffect(() => {
    if (isInitialized === false) {
      setIsInitialized(true);
      window.state.bulb = {
        "uniforms": uniforms,
        "setFloat": setBulbFloat,
        "setAnimate": setAnimating,
        "setPos": setPos,
        "setScale": setScale
      };
    };
  }, [uniforms, isInitialized]);


  useEffect(() => {
    if (windowWidth > windowHeight) {
      material.current.uniforms.width.value = 600.0;
      material.current.uniforms.height.value = 600.0 / windowWidth * windowHeight;
    } else {
      material.current.uniforms.height.value = 600.0;
      material.current.uniforms.width.value = 600.0 / windowHeight * windowWidth;
    }
  }, [windowWidth, windowHeight]);


  useFrame(() => {
    if (material.current && animating ) {
      let ps, sc;

      material.current.uniforms.time.value += 0.01;
      const time = material.current.uniforms.time.value;
      const floating = bulbFloat;


      if (floating === true) {
        ps = [
          Math.sin(time * 0.01) * 0.01 - 0.005,
          Math.sin(time * 0.01) * 0.01 - 0.005
        ];

        sc = Math.abs(Math.sin(time * 0.1) * 24) + 20;
      } else {
        ps = bulbPos;
        sc = bulbScale;
      };


      // lerpy simData gets copied over here here
      targetSimData.current = {
        phase: simData.phase ? simData.phase : currentSimData.current.phase,
        power: simData.power ? Math.min(9, Math.max(-9, simData.power)) : Math.min(9, Math.max(-9, currentSimData.current.power)),

        position: simData.position ? simData.position : currentSimData.current.position,
        scale: simData.scale ? simData.scale : currentSimData.current.scale
      };

      // Lerpin happens here
      currentSimData.current = {
        power: lerp(currentSimData.current.power, targetSimData.current.power, lerpAmount * 0.1),
        phase: lerpArray(currentSimData.current.phase, targetSimData.current.phase, lerpAmount * 0.1),
        position: lerpArray(currentSimData.current.position, targetSimData.current.position, lerpAmount * 2),
        scale: lerpArray(currentSimData.current.scale, targetSimData.current.scale, lerpAmount * 2),
      };

      const timestamp = new Date().getTime();

      material.current.uniforms.power.value = currentSimData.current.power;
      material.current.uniforms.phase.value = currentSimData.current.phase;
      material.current.uniforms.diffuseColor.value = new THREE.Vector4(Math.sin(timestamp * 0.0001 + 60), Math.sin(timestamp * 0.0001), Math.sin(timestamp * 0.0001 - 20), 1.0);

      if ( bulbFloat ) {
        ps = Math.sin(time * 0.01) * 0.01 - 0.005;
        sc = Math.abs(Math.sin(time * 0.1) * 24) + 20;
      } else {
        ps = currentSimData.current.position;
        sc = currentSimData.current.scale;
      };

      // Rifke's magic numbers
      material.current.uniforms.modelViewProjectMatrixInverse.value = [
        0.8572106957435608,
        0.0,
        0.0,
        0.0,
        0.0,
        0.4142135679721832,
        0.0,
        ps, // position along y=x roughly in the range [-0.01, 0.01]
        Math.sin(time * 0.01) * 0.01 - 0.005,
        10.0,
        -9.0999998092651367,
        -4.999999046325684,
        0.0,
        0.0,
        sc, // scale of the bulb ~ [10, 100] give pretty useful expressions... below 30 gives a mandlebulb full view
        6.0
      ];
    }
  });

  return (
    <mesh ref={mesh} >
      <planeBufferGeometry args={[48, 48]} />
      <shaderMaterial
        attach="material"
        ref={material}
        uniforms={uniforms}
        fragmentShader={lavaFragmentShader}
        vertexShader={lavaVertexShader}
        uniformsNeedUpdate={true}
        needsUpdate={true}
      />
    </mesh>
  );
}


const ThreeSceneFractal = (props) => {

  const camera = useRef();

  return (
    <group>
      <Camera camera={camera} />
      <color attach="background" args={["black"]} />
      <Mandelbulb {...props} camera={camera} />
    </group>
  )
}

export default ThreeSceneFractal;
