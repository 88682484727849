import { useEffect } from 'react';

const ReceiveDataFromWebsocket = (props) => {

  const { websocket, setReferences, messageHistory, setMessageHistory, transcriptHistory, setTranscriptHistory, configData, debug, ignoreSim, setTranscriptTitle, setSimData, socketUrl } = props;

  useEffect(() => {

    let timeout;

    const handleReceiveMessage = (event) => {
      const parcel = JSON.parse(event.data);
      if (debug < 2) {
        console.log("Parcel Received", parcel);
      }
      switch (parcel.msg) {
        case "handshake":
          if (parcel.sign === "connection successful") {
            websocket.send(JSON.stringify({
              "msg": "request_references",
              "max_references": 100
            }));
          }
          break;
        case "reference_list":
          setReferences(parcel.references);
          break;
        case "chat":
          setMessageHistory([
            ...messageHistory,
            {
              type: 'received',
              text: parcel.text,
              voice: parcel.voice
            }
          ]);
          break;
        case "text":
          timeout = setTimeout(() => {
            if (parcel.what === 'word' && transcriptHistory[0]) {
              const newTranscriptHistory = [...transcriptHistory];
              newTranscriptHistory[transcriptHistory.length - 1].text = transcriptHistory[transcriptHistory.length - 1].text + " " + parcel.text;
              newTranscriptHistory[transcriptHistory.length - 1].ref_ids = [...new Set([
                ...newTranscriptHistory[transcriptHistory.length - 1].ref_ids,
                ...parcel.ref_ids
              ])];
              newTranscriptHistory[transcriptHistory.length - 1].show_refs = parcel.show_refs;
              setTranscriptHistory(newTranscriptHistory);
            } else if (parcel.what === 'phrase' || (parcel.what === 'word' && !transcriptHistory[0])) {
              setTranscriptHistory([
                ...transcriptHistory,
                {
                  ...parcel,
                  type: 'received'
                }
              ]);
            } else if (parcel.what === 'title') {
              setTranscriptTitle(parcel.text);
            }
          }, transcriptHistory[0] ? configData.textDelayInMilliseconds : 1);
          break;
        case "sim":

          if(debug < 2) {
            console.log("BULB ARRIVED:", parcel.mandelbulb);
          };

          if(!ignoreSim) {
            setSimData(parcel.mandelbulb);
          };
          break;
        default:
          console.log('Uh oh.. unknown message type! ' + parcel.msg)
          break;
      }
    }
    typeof websocket.addEventListener !== 'undefined' && websocket.addEventListener('message', handleReceiveMessage);

    return () => {
      typeof websocket.removeEventListener !== 'undefined' && websocket.removeEventListener('message', handleReceiveMessage);
      clearTimeout(timeout);
    }

  }, [messageHistory, transcriptHistory, socketUrl, configData.textDelayInMilliseconds, debug, ignoreSim, setMessageHistory, setTranscriptHistory, setSimData, setReferences, setTranscriptTitle, websocket]);

  return (
    <p
      style={{
        position: 'absolute',
        zIndex: 999,
        display: 'none',
        left: '50px',
        margin: '20px 0',
        color: 'green'
      }}
    >websocket connection status:: {props.connectionStatus}</p>
  );
}

export default ReceiveDataFromWebsocket;
