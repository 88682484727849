import { useRef, useState, useEffect, Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCookieConsentValue } from 'react-cookie-consent';
import Content from './components/Content';
import * as _ from 'underscore';

const App = (props) => {

  const [configData, setConfigData] = useState({});
  const [socketUrl, setSocketUrl] = useState('');
  const [readyState, setReadyState] = useState(-1);
  const [connectionStatus, setConnectionStatus] = useState('closed');
  const [messageHistory, setMessageHistory] = useState([]);
  const [transcriptHistory, setTranscriptHistory] = useState([]);
  const [typingParagraphIndex, setTypingParagraphIndex] = useState(0);
  const [transcriptTitle, setTranscriptTitle] = useState('Will future generations speak of the wisdom of their ancestors as we are inclined to speak of ours?');
  const [simData, setSimData] = useState({
    a: 2, b: 2, t: 0
  });
  const [transcriptIsActive, setTranscriptIsActive] = useState(true);
  const [chatIsMinimised, setChatIsMinimised] = useState(true);
  const [references, setReferences] = useState([]);

  const [websocket, setWebsocket] = useState({});
  const [debug, setDebug] = useState(2);
  const [ignoreSim, setIgnoreSim] = useState(false);
  const [noCaptureStream, setNoCaptureStream] = useState(false);

  // USE window.state.setDebug(1) and other functions...
  useEffect(() => {
    window.state = {
      "setSimData": setSimData,
      "setDebug": setDebug,
      "setIgnoreSim": setIgnoreSim
    };
  }, []);

  useEffect(() => {
    fetch("/config.json").then(
      function(res){
      return res.json()
    }).then(function(data){
    // store Data in State Data Variable
      setConfigData(data);
      if (data.websocketAddress) {
        setSocketUrl(data.websocketAddress);
        setWebsocket(new WebSocket(data.websocketAddress));
      }
    }).catch(
      function(err){
        console.log(err, ' error')
      }
    )
  }, []);

  useEffect(() => {

    if (socketUrl !== '') {

      websocket.onopen = () => {
        console.log("Server Connection Established");
        const greeting = "new visitor";
        websocket.send(greeting);
        setReadyState(1);
        setConnectionStatus('open');
      };

      websocket.onclose = (e) => {
        console.log(e);
        setConnectionStatus('closed');
      }
    }
  }, [socketUrl, websocket]);

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const [isLoading, setIsLoading] = useState(true);
  const [cookiesAreAccepted, setCookiesAreAccepted] = useState(getCookieConsentValue() ? true : false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isTouchScreen, setIsTouchScreen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const audio = useRef();
  const audioFirefox = useRef();
  const audioCtx = useRef();
  const analyser = useRef();
  const [bufferLength, setBufferLength] = useState(0);
  const dataArray = useRef();

  const canvas = useRef();
  const faviconCanvas = useRef();
  const raf = useRef();

  useEffect(() => {
    if (location.pathname !== '/') {
      setIsLoading(false);
    }
  }, [location.pathname]);


  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  const handleMouseMove = (e) => {
    let x, y;
    if (e.clientX && e.clientY) {
      x = e.clientX;
      y = e.clientY;
    }
    if (e.touches) {
      if (isTouchScreen !== true) {
        setIsTouchScreen(true);
      }
      if (e.touches[0]) {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      }
    } else {
      if (isTouchScreen === null) {
        setIsTouchScreen(false);
      }
    }
    if (x && y) {
      setMousePosition({
        x: x,
        y: y
      });
    }
  }

  const handleMouseMoveThrottled = _.throttle(handleMouseMove, 60);

  return (
    <Fragment>
      <Content
        {...props}
        handleMouseMoveThrottled={handleMouseMoveThrottled}
        mousePosition={mousePosition}
        isTouchScreen={isTouchScreen}
        navigate={navigate}
        messageHistory={messageHistory}
        setMessageHistory={setMessageHistory}
        transcriptHistory={transcriptHistory}
        setTranscriptHistory={setTranscriptHistory}
        setTranscriptTitle={setTranscriptTitle}
        references={references}
        setReferences={setReferences}
        websocket={websocket}
        readyState={readyState}
        location={location}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        windowWidth={windowDimensions.windowWidth}
        windowHeight={windowDimensions.windowHeight}
        cookiesAreAccepted={cookiesAreAccepted}
        setCookiesAreAccepted={setCookiesAreAccepted}
        audio={audio}
        audioFirefox={audioFirefox}
        bufferLength={bufferLength}
        analyser={analyser}
        dataArray={dataArray}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        connectionStatus={connectionStatus}
        canvas={canvas}
        simData={simData}
        setSimData={setSimData}
        transcriptIsActive={transcriptIsActive}
        setTranscriptIsActive={setTranscriptIsActive}
        chatIsMinimised={chatIsMinimised}
        setChatIsMinimised={setChatIsMinimised}
        configData={configData}
        transcriptTitle={transcriptTitle}
        typingParagraphIndex={typingParagraphIndex}
        setTypingParagraphIndex={setTypingParagraphIndex}
        debug={debug}
        ignoreSim={ignoreSim}
        faviconCanvas={faviconCanvas}
        audioCtx={audioCtx}
        raf={raf}
        setBufferLength={setBufferLength}
        noCaptureStream={noCaptureStream}
        setNoCaptureStream={setNoCaptureStream}
      />
    </Fragment>
  );
}

export default App;
