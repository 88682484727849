import { useRef, useState, useEffect, Suspense } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { motion } from 'framer-motion';
import * as THREE from 'three';
import ThreeScene from './ThreeSceneFractal';

const FPSLimiter = (props) => {
  const { fps } = props;
  const { set } = useThree();

  useEffect(() => {
    const timeUntilNextFrame = (1000 / (fps !== 0 ? fps : 1));

    let interval = setInterval(() => {
      set({ frameloop: 'always' });
      set({ frameloop: 'never' });
    }, Math.max(0, timeUntilNextFrame));

    return () => {
      clearInterval(interval);
    };
  }, [fps, set]);

  return null;
};

const SceneResizer = (props) => {
  const { fps } = props;
  const three = useThree();
  const framesElapsed = useRef(0);

  useFrame(({ clock }) => {
    const { elapsedTime } = clock;
    framesElapsed.current += 1;

    if (framesElapsed.current / elapsedTime < fps * 0.8 && elapsedTime > fps * 0.2) {
      three.setDpr(Math.max(0.75, three.viewport.dpr * 0.8));
      framesElapsed.current = elapsedTime * fps;
    }
  });

  useEffect(() => {
    const onWindowResize = () => {
      if (three.size.width > 640) {
        if (three.viewport?.dpr > 1) {
          three.setDpr(1)
        }
      } else {
        if (three.viewport.dpr < 1) {
          three.setDpr(1)
        } else if (three.viewport?.dpr !== 2) {
          three.setDpr(2)
        }
      }
    }

    onWindowResize();
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [three]);

  return (null)
}

const ThreeCanvas = (props) => {

  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  const camera = useRef();
  const [fps] = useState(24);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="three-canvas__wrapper"
    >
      <Canvas
        camera={{ fov: 75, position: [0, 0, 2]}}
        pixelRatio={Math.min(2, isMobile ? window.devicePixelRatio : 1)}
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.CineonToneMapping;
          gl.toneMappingExposure = 1.25;
          gl.outputEncoding = THREE.sRGBEncoding;
        }}
      >
        <FPSLimiter fps={fps} />
        <SceneResizer fps={fps} />
        <ambientLight intensity={0.6} />
        <pointLight position={[150, 150, 150]} intensity={0.55} />
        <Suspense fallback={null}>
          <ThreeScene {...props} isMobile={isMobile} camera={camera} />
        </Suspense>
      </Canvas>
    </motion.div>
  )
}

export default ThreeCanvas;